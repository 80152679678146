import React from 'react';
import _times from 'lodash.times';
import { cn } from '@/lib/tailwind';
import Rectangle from '../../skeleton/Rectangle';

const Field = ({ className }: { className?: string }) => (
  <div className={className}>
    <Rectangle height={16} width={80} />
    <Rectangle height={44} style={{ marginTop: '8px' }} />
  </div>
);

const FormSkeleton = () => {
  return (
    <div className="flex flex-col w-full gap-4 bg-white rounded-[12px]">
      <Rectangle height={32} width="60%" style={{ marginBottom: '8px' }} />
      <Field className="w-full" />
      <div className="flex flex-wrap justify-between w-full">
        {_times(6, index => (
          <Field
            key={index}
            className={cn(
              'w-full tablet:w-[calc(50%-12px)]',
              index === 5 ? 'mb-0' : 'mb-4',
              index === 5 || index == 4 ? 'mb-0' : 'mb-4',
            )}
          />
        ))}
      </div>
      <Rectangle height={32} width="100%" />
      <Rectangle height={40} width={200} radius={24} />
    </div>
  );
};

export default FormSkeleton;
