export enum QueryStatus {
  IDLE,
  LOADING,
  ERROR,
  SUCCESS,
  FETCHING,
}

export enum FetchPolicy {
  CACHE_FIRST,
  CACHE_AND_NETWORK,
  NETWORK_ONLY,
}
