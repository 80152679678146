export const getAutoCompleteStatus = (validIdSelector: string) => {
  const emailSelector = `${validIdSelector} input[name=email]`;
  const firstNameSelector = `${validIdSelector} input[name=firstname]`;
  const lastNameSelector = `${validIdSelector} input[name=lastname]`;
  const companySelector = `${validIdSelector} input[name=company]`;
  const jobTitleSelector = `${validIdSelector} input[name=jobtitle]`;
  const phoneSelector = `${validIdSelector} input[name=phone]`;
  const emailAutocomplete = document
    .querySelector(emailSelector)
    ?.getAttribute('value');
  const firstNameAutocomplete = document
    .querySelector(firstNameSelector)
    ?.getAttribute('value');
  const lastNameAutocomplete = document
    .querySelector(lastNameSelector)
    ?.getAttribute('value');
  const companyAutocomplete = document
    .querySelector(companySelector)
    ?.getAttribute('value');
  const jobTitleAutocomplete = document
    .querySelector(jobTitleSelector)
    ?.getAttribute('value');
  const phoneAutocomplete = document
    .querySelector(phoneSelector)
    ?.getAttribute('value');

  const allAutoCompleted =
    !!emailAutocomplete &&
    !!firstNameAutocomplete &&
    !!lastNameAutocomplete &&
    !!companyAutocomplete &&
    !!jobTitleAutocomplete &&
    !!phoneAutocomplete;

  return {
    allAutoCompleted: allAutoCompleted,
    firstNameAutocomplete: firstNameAutocomplete,
  };
};
