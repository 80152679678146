import { FetchPolicy, useQuery } from '@/hooks/useQuery';
import { serializeUrlParams } from '@/utils/url';

const basicValidation = (email: string): boolean => {
  if (email.indexOf('@') === -1) return false;

  const lastIndexofAt = email.lastIndexOf('@');
  const domain = email.slice(lastIndexofAt + 1);

  if (domain.indexOf('.') === -1) return false;

  return true;
};

function isEmailDomainRestricted(
  email: string,
  emailDomainsRestrictionRegex: string,
) {
  const emailDomainsRestrictionRegexExp = new RegExp(
    emailDomainsRestrictionRegex,
  );
  return emailDomainsRestrictionRegexExp.test(email);
}

export const useValidateEmailQuery = ({
  email,
  emailDomainsRestrictionRegex,
}: {
  email: string;
  emailDomainsRestrictionRegex?: string;
}) =>
  useQuery(
    ['emailable', email],
    `https://api.emailable.com/v1/verify${serializeUrlParams({
      email,
      api_key: 'live_a36b2ba056a58a8fc84e',
    })}`,
    {
      skip:
        !email.trim() ||
        !basicValidation(email.trim()) ||
        (emailDomainsRestrictionRegex
          ? isEmailDomainRestricted(email, emailDomainsRestrictionRegex)
          : false),
      fetchPolicy: FetchPolicy.CACHE_FIRST,
    },
  );
