import { AnyObject } from '@/types';
import { isWindowDefined } from '@/utils/isWindowDefined';

const SCRIPT_SRC = `//js.hsforms.net/forms/v2.js`;
const SCRIPT_LOADING_ATTRIBUTE = 'data-script-loading';

type Props = {
  validId: string;
  sfCampaign: string;
  formId: string;
  validIdSelector: string;
  submitAction?: object;
  ctaLabel?: string;
  // TODO: fix types here
  t: AnyObject;
  privacyPolicyText?: string;
};

type WindowWithHbspt = typeof window & {
  hbspt: {
    forms: {
      create: (options: any) => void;
    };
  };
};

export const createHubspotScript = ({
  validId,
  sfCampaign,
  formId,
  validIdSelector,
  submitAction,
  ctaLabel,
  privacyPolicyText,
  t,
}: Props) => {
  let script: HTMLScriptElement | null = document.querySelector(
    `script[src="${SCRIPT_SRC}"]`,
  );

  const loadFormCallback = () => {
    loadForm({
      formId,
      sfCampaign,
      submitAction,
      validId,
      validIdSelector,
      ctaLabel,
      privacyPolicyText,
      t,
    });
  };

  const createForm = () => {
    script = document.createElement('script');
    script.src = SCRIPT_SRC;
    script.async = true;
    script.defer = true;
    script.setAttribute(SCRIPT_LOADING_ATTRIBUTE, 'true');
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      script!.removeAttribute(SCRIPT_LOADING_ATTRIBUTE);
      loadFormCallback();
    });
  };

  if (!script || script.hasAttribute(SCRIPT_LOADING_ATTRIBUTE)) {
    createForm();
  } else {
    loadFormCallback();
  }
};

function loadForm({
  validId,
  sfCampaign,
  formId,
  validIdSelector,
  submitAction,
  ctaLabel,
  t,
  privacyPolicyText,
}: Props) {
  try {
    if (isWindowDefined() && (window as WindowWithHbspt).hbspt && validId) {
      (window as WindowWithHbspt).hbspt.forms.create({
        region: 'na1',
        portalId: '19843870',
        formId: formId,
        target: validIdSelector,
        ...submitAction,
        sfdcCampaignId: sfCampaign,
        formInstanceId: validId,
        translations: {
          en: {
            invalidEmailFormat: t('Email must be formatted correctly.'),
            phoneInvalidRangeTooShort: t(
              'Please enter a phone number that’s at least 7 numbers long.',
            ),
            phoneInvalidCharacters: t('Invalid Phone Characters'),
            required: t('Please complete this required field'),
            missingSelect: t('Please select an option from the dropdown menu.'),
            submitText: t(ctaLabel || 'Request Demo'),
            fieldLabels: {
              email: t('Work Email'),
              firstname: t('First Name'),
              lastname: t('Last Name'),
              company: t('Company Name'),
              jobtitle: t('Job Title'),
              phone: t('Phone Number'),
              country_dropdown: t('Country'),
              email_communication_agreement: t(
                'Yes, I would like to receive marketing offers from Sprinklr',
              ),
              partner_opt_in: t(
                'I agree to receive email communications from the Sprinklr partner reflected on this webpage. I can contact the Sprinklr partner to unsubscribe at any time.',
              ),
              privacy_policy_agreement:
                privacyPolicyText || t.raw('Privacy Policy'),
              job_function__c: t('Job Function'),
              role__c: t('Job Level'),
              how_did_you_hear_about_us: t('How did you hear about us?'),
            },
          },
        },
      });
    }
  } catch (err) {
    console.error('error while loading form', err);
  }
}
