import { MutableRefObject, useEffect } from 'react';
import { AnyObject } from '@/types';
import { QueryStatus } from '@/hooks/useQuery';
import { getErrorMessage } from '../helpers';

type Props = {
  isHubspotFormLoaded: boolean;
  formRef: MutableRefObject<AnyObject>;
  data: AnyObject;
  status: QueryStatus;
};

export const useEmailFieldStatus = ({
  isHubspotFormLoaded,
  formRef,
  data,
  status,
}: Props) => {
  useEffect(() => {
    if (!isHubspotFormLoaded) {
      return;
    }
    const emailInput = formRef.current.querySelector('input[type=email]');
    if (!emailInput) {
      return;
    }
    emailInput.parentElement.classList.add('emailable-spinner-container');
    const errorMessage = getErrorMessage(data);
    if (!emailInput.classList.contains('error') && errorMessage) {
      emailInput.classList.add('emailable-error-input');
    }
    return () => {
      emailInput.classList.remove('emailable-error-input');
    };
  }, [isHubspotFormLoaded, status, data, formRef]);
};
