import { useCallback } from 'react';
import { AnyObject } from '@/types';

const useTrackEvent = (eventName: string) => {
  const trackEvent = useCallback(
    (payload: AnyObject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window?.analytics?.track) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.analytics.track(eventName, {
          ...payload,
        });
      }
    },
    [eventName],
  );

  return trackEvent;
};

export { useTrackEvent };
