import { getPersistedTacticId } from '@/utils/tacticId/utils';
import { AnyObject } from '@/types';

type Props = {
  validIdSelector: string;
  on24_id: string;
  on24_key: string;
  validId: string;
  sfCampaign: string;
  assetId?: string;
  formType?: string;
  setPhoneError: (e?: AnyObject) => void;
};

type FormField = {
  validIdSelector: string;
  value?: string;
};

function updateFormField({ validIdSelector, value }: FormField) {
  const node = document.querySelector(validIdSelector)! as HTMLInputElement;
  if (node) {
    node.value = value ?? '';
  }
}

function updateFormFields(formFields: FormField[]) {
  formFields.forEach(field => updateFormField(field));
}

export const handleHiddenFields = ({
  validIdSelector,
  formType,
  on24_id,
  on24_key,
  validId,
  sfCampaign,
  assetId,
  setPhoneError,
}: Props) => {
  const on24IdSelector = `${validIdSelector} input[name=on24_id]`;
  const on24KeySelector = `${validIdSelector} input[name=on24_key]`;
  const sfCampaignSelector = `${validIdSelector} input[name=sf_campaign]`;
  const assetIdSelector = `${validIdSelector} input[name=asset_id]`;
  const formInstanceSelector = `${validIdSelector} input[name=form_instance]`;
  const formSelector = `${validIdSelector} input[name=data_form_type]`;
  const formElementSelector = `${validIdSelector} form`;
  const phoneSelector = `${validIdSelector} input[name=phone]`;
  const tacticIdSelector = `${validIdSelector} input[name=tactic_id]`;
  const tacticId = getPersistedTacticId();

  const formFields = [
    {
      validIdSelector: on24IdSelector,
      value: on24_id,
    },
    {
      validIdSelector: on24KeySelector,
      value: on24_key,
    },
    {
      validIdSelector: sfCampaignSelector,
      value: sfCampaign,
    },
    {
      validIdSelector: assetIdSelector,
      value: assetId,
    },
    {
      validIdSelector: formInstanceSelector,
      value: validId,
    },
    {
      validIdSelector: formSelector,
      value: formType,
    },
    {
      validIdSelector: tacticIdSelector,
      value: tacticId,
    },
  ];

  updateFormFields(formFields);

  const formNode = document.querySelector(formElementSelector);
  if (formNode) {
    formNode.setAttribute('data-form_type', formType ?? '');
  }

  document.querySelector(phoneSelector)?.addEventListener('blur', e => {
    const target = e.target as HTMLInputElement;
    setPhoneError(
      target?.className === ('hs-input invalid error' || 'hs-error-msg'),
    );
  });
};
