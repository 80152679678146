import { MutableRefObject, useEffect } from 'react';

import { QueryStatus } from '@/hooks/useQuery';

import { AnyObject } from '@/types';
import { isValidEmail } from '../helpers';

type Props = {
  isHubspotFormLoaded: boolean;
  formRef: MutableRefObject<AnyObject>;
  data: AnyObject;
  email: AnyObject;
  status: QueryStatus;
};

export const useDisableSubmit = ({
  isHubspotFormLoaded,
  formRef,
  data,
  email,
  status,
}: Props) => {
  useEffect(() => {
    if (!isHubspotFormLoaded || !email) {
      return;
    }
    const submitButton = formRef.current.querySelector('input[type=submit]');
    if (!submitButton) {
      return;
    }
    if (status !== QueryStatus.ERROR && !isValidEmail(data)) {
      submitButton.disabled = true;
      submitButton.classList.add('submit-button-disabled');
    }
    return () => {
      submitButton.disabled = false;
      submitButton.classList.remove('submit-button-disabled');
    };
  }, [data, isHubspotFormLoaded, email, status, formRef]);
};
