import { MutableRefObject, useEffect, useState } from 'react';

import { AnyObject } from '@/types';
import { useValidateEmailQuery } from './useValidateEmailQuery';
import { useDisableSubmit } from './useDisableSubmit';
import { useEmailFieldEventListener } from './useEmailFieldEventListener';
import { useEmailFieldStatus } from './useEmailFieldStatus';
import { useEmailableTrackEvent } from './useEmailableTrackEvent';

type Props = {
  isHubspotFormLoaded: boolean;
  formRef: MutableRefObject<AnyObject>;
  emailDomainsRestrictionRegex?: string;
};

export const useEmailableValidation = ({
  isHubspotFormLoaded,
  formRef,
  emailDomainsRestrictionRegex,
}: Props) => {
  const [email, setEmail] = useState('');
  const [debouncedEmail, setDebouncedEmail] = useState('');

  useEffect(() => {
    const handlePrefilledValue = (event: AnyObject) => {
      const eventType = event?.data?.type;
      if (
        eventType === 'hsFormCallback' &&
        event.data.eventName === 'onFormReady'
      ) {
        const emailInput = formRef.current.querySelector('input[type=email]');
        if (!emailInput) {
          return;
        }
        const email = emailInput.getAttribute('value');
        setEmail(email);
        setDebouncedEmail(email);
      }
    };
    window.addEventListener('message', handlePrefilledValue);
    return () => {
      window.removeEventListener('message', handlePrefilledValue);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, status } = useValidateEmailQuery({
    email: debouncedEmail,
    emailDomainsRestrictionRegex,
  });

  useEmailFieldEventListener({
    isHubspotFormLoaded,
    formRef,
    setEmail,
    setDebouncedEmail,
  });

  useEmailFieldStatus({
    isHubspotFormLoaded,
    formRef,
    data,
    status,
  });

  useDisableSubmit({
    isHubspotFormLoaded,
    formRef,
    email,
    data,
    status,
  });

  useEmailableTrackEvent({
    data,
  });

  return {
    data,
    status,
  };
};
