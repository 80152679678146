// libs
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import _isEqual from 'lodash.isequal';

// utils
import { InMemoryCache } from './InMemoryCache';
import { generateKey } from './utils';

// types
import { QueryState, QueryOptions, QueryKey, QueryResult } from './types';

// constants
import { QueryStatus, FetchPolicy } from './constants';

const cache = new InMemoryCache();

const INITIAL_STATE = {
  status: QueryStatus.IDLE,
};

const useQuery = <TData, TError>(
  keys: QueryKey,
  url: string,
  options?: QueryOptions,
): QueryResult<TData, TError> => {
  const {
    payload,
    skip,
    fetchPolicy = FetchPolicy.CACHE_AND_NETWORK,
  } = options ?? ({} as QueryOptions);
  const queryKey = useMemo(() => generateKey(keys), [keys]);

  const abortControllerRef = useRef<AbortController>();
  const [state, setState] = useState<QueryState<TData, TError>>(INITIAL_STATE);

  const fetchNetworkOnly = useCallback(async () => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await fetch(url, {
        method: 'GET',
        body: payload,
        signal: abortController.signal,
      });

      const data = await response.json();
      const cachedData = cache.get(queryKey);

      if (!cachedData || !_isEqual(cachedData, data)) {
        cache.set(queryKey, data);
      }

      setState({
        data: cache.get(queryKey),
        status: QueryStatus.SUCCESS,
      });

      return data;
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        // Abort error
        return;
      }

      setState(prevState => ({
        ...prevState,
        status: QueryStatus.ERROR,
        error: error as TError,
      }));
    }
  }, [url, payload, queryKey]);

  const fetchCacheAndNetwork = useCallback(async () => {
    const cachedData = cache.get(queryKey);

    if (cachedData && fetchPolicy === FetchPolicy.CACHE_FIRST) {
      setState({
        data: cachedData,
        status: QueryStatus.SUCCESS,
      });
      return;
    }

    if (cachedData && fetchPolicy === FetchPolicy.CACHE_AND_NETWORK) {
      setState({
        data: cachedData,
        status: QueryStatus.FETCHING,
      });
    }

    return fetchNetworkOnly();
  }, [queryKey, fetchNetworkOnly, fetchPolicy]);

  const refetch = useCallback(() => {
    setState({
      status: QueryStatus.LOADING,
    });

    return fetchNetworkOnly();
  }, [fetchNetworkOnly]);

  useEffect(() => {
    if (!skip) {
      fetchCacheAndNetwork();
    }

    return () => {
      // if keys changes or hook gets unmount - abort any in-flight request
      abortControllerRef.current?.abort();
      abortControllerRef.current = undefined;
    };
  }, [queryKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data: state.data,
    isLoading: state.status === QueryStatus.LOADING,
    error: state.error,
    status: state.status,
    refetch,
  };
};

export { useQuery };
