import { MutableRefObject } from 'react';

import { AnyObject } from '@/types';
import { DEFAULT_LANGUAGE_CONFIG } from '@/constants/locales';
import { REDIRECT_DEMO_LINKS_LOOKUP } from './constants';

export const formAccessibilityHelper = (
  formRef: MutableRefObject<AnyObject>,
) => {
  const listElements = formRef?.current?.querySelectorAll('ul');
  const listElementArr = listElements && Array.from(listElements);
  listElementArr?.forEach((item: AnyObject) => {
    const isPrivacy = item
      ?.getAttribute('data-reactid')
      ?.includes('privacy_policy_agreement');
    if (isPrivacy) item.setAttribute('role', 'presentation');
  });
};

export const getSubmitAction = (
  redirectSlug: string,
  localizedSlug: string,
) => {
  const submitAction = redirectSlug
    ? { redirectUrl: `${localizedSlug}` }
    : { inlineMessage: '   ' };
  return submitAction;
};

export const getFormId = ({
  formType,
  alternateFormId,
  customFormId,
}: {
  formType?: string;
  alternateFormId?: string;
  customFormId?: string;
}) => {
  if (customFormId) return customFormId;

  switch (true) {
    case alternateFormId === 'Partner Form':
      return 'e501da6b-b6f5-480d-981f-e1b924b9ebc6';
    case alternateFormId === 'CUSTOM':
      return customFormId
        ? customFormId
        : '856f51b0-1ccb-495b-891c-b147dbd9bcb2';
    case formType === 'blog_subscription':
      return '59460142-3bd4-4e0d-be83-ee47f7d91113';
    default:
      return '856f51b0-1ccb-495b-891c-b147dbd9bcb2';
  }
};

export const getIsSubmittedForm = (
  submittedFormValue: AnyObject,
  data: AnyObject,
  validId: string,
) => {
  let hsContext;
  submittedFormValue = null;
  window.localStorage.removeItem('submittedFormValue');
  submittedFormValue =
    data.find(({ name }: AnyObject) => name === 'form_instance')?.value ??
    data['form_instance'];
  if (!submittedFormValue) {
    try {
      hsContext =
        data.find(({ name }: AnyObject) => name === 'hs_context')?.value ??
        data['hs_context'];
      const parsedHsContext = JSON.parse(hsContext);
      submittedFormValue = parsedHsContext.formInstanceId;
    } catch (e) {
      console.error(e);
    }
  }
  window.localStorage.setItem('submittedFormValue', submittedFormValue);
  return submittedFormValue === validId;
};

export const handleOn24Submission = (
  data: AnyObject,
  on24_id: string,
  on24_key: string,
) => {
  const firstName = data.find(({ name }: AnyObject) => name === 'firstname')
    ?.value;
  const lastName = data.find(({ name }: AnyObject) => name === 'lastname')
    ?.value;
  const email = data.find(({ name }: AnyObject) => name === 'email')?.value;
  const company = data.find(({ name }: AnyObject) => name === 'company')?.value;
  const on24Params = new URLSearchParams({
    eventid: on24_id,
    key: on24_key,
    firstname: firstName,
    lastname: lastName,
    email: email,
    company: company,
  });
  const fetchURL = `https://event.on24.com/utilApp/r?${on24Params}`;
  const isOn24Event = !!(on24_id && on24_key);
  if (isOn24Event) {
    fetch(fetchURL, { method: 'POST', mode: 'no-cors' }).catch(error =>
      console.error(error),
    );
  }
};

// custom redirect by category type
export const getLocalizedSlug = ({
  productCategory,
  locale,
  redirectSlug,
  formType,
}: {
  locale: string;
  redirectSlug: string;
  productCategory?: string;
  formType?: string;
}) => {
  if (formType === 'user_brand_add_request') {
    return locale === DEFAULT_LANGUAGE_CONFIG.prefixSlug
      ? redirectSlug
      : `/${locale}${redirectSlug}`;
  }

  let localizedSlug;
  const category =
    typeof productCategory === 'string' ? [productCategory] : productCategory;

  if (!!category && category?.length < 2) {
    const redirectDemoSlug =
      REDIRECT_DEMO_LINKS_LOOKUP[category[0]] ??
      REDIRECT_DEMO_LINKS_LOOKUP['No Product Category'];

    localizedSlug =
      locale === DEFAULT_LANGUAGE_CONFIG.prefixSlug
        ? redirectDemoSlug
        : `/${locale}${redirectDemoSlug}`;
  } else {
    localizedSlug =
      locale === DEFAULT_LANGUAGE_CONFIG.prefixSlug
        ? redirectSlug
        : `/${locale}${redirectSlug}`;
  }
  return localizedSlug;
};

export const translateDropdownOptions = (t: AnyObject) => {
  // @typescript-eslint/ban-types
  const dropDownOptions = document.getElementsByTagName('option');
  const pleaseSelect = t(
    document.querySelector('option[disabled]')?.textContent,
  );

  if (dropDownOptions.length) {
    for (let i = 0; i < dropDownOptions.length; i++) {
      dropDownOptions[i].text = t(dropDownOptions[i].text);
    }
    const localizedOptionsArray = Array.from(dropDownOptions)
      .filter(el => el.text !== pleaseSelect) //Skip default selection value
      .sort((a, b) => a.text.localeCompare(b.text));

    for (let i = 0; i < localizedOptionsArray.length; i++) {
      const listParent = localizedOptionsArray[i].parentNode!;
      listParent.appendChild(localizedOptionsArray[i]);
    }
  }
};
