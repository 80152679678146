const BACKEND_TO_HUBSPOT_MAP: Record<string, string> = {
  Bolivia: 'B0',
  Bonaire: 'Bonaire, Sint Eustatius and Saba',
  'British Virgin Islands': 'Virgin Islands, British',
  Brunei: 'Brunei Darussalam',
  'Cocos Islands': 'Cocos (Keeling) Islands',
  Curacao: 'Curaçao',
  'Democratic Republic of the Congo': 'Congo, the Democratic Republic of the',
  'East Timor': 'Timor-Leste',
  'Falkland Islands': 'Falkland Islands (Malvinas)',
  Iran: 'Iran, Islamic Republic of',
  'Ivory Coast': "Cote d'Ivoire",
  Laos: "Lao People's Democratic Republic",
  Libya: 'Libyan Arab Jamahiriya',
  Macedonia: 'Macedonia, the former Yugoslav Republic of',
  Micronesia: 'Micronesia, Federated States of',
  Moldova: 'Moldova, Republic of',
  'North Korea': "Korea, Democratic People's Republic of",
  'Palestinian Territory': 'Palestinian Territory, Occupied',
  'Republic of the Congo': 'Congo, the Democratic Republic of the',
  Russia: 'Russian Federation',
  'Saint Barthelemy': 'Saint Barthélemy',
  'Saint Helena': 'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Martin': 'Saint Martin (French part)',
  'Sint Maarten': 'Sint Maarten (Dutch part)',
  Syria: 'Syrian Arab Republic',
  Taiwan: 'Taiwan, Province of China',
  Tanzania: 'Tanzania, United Republic of',
  'U.S. Virgin Islands': 'Virgin Islands, U.S.',
  Vatican: 'Holy See (Vatican City State)',
  Venezuela: 'Venezuela, Bolivarian Republic of',
  Vietnam: 'Viet Nam',
};

export const getCountryLabel = (): Promise<string> =>
  fetch(
    'https://external-prod.sprinklr.com/rest/ip-geolocation/identify-country',
  )
    .then(res => res.json())
    .then(res => {
      const countryLabel = res.countryName;

      return BACKEND_TO_HUBSPOT_MAP[countryLabel] ?? countryLabel;
    });
