import { MutableRefObject, useEffect } from 'react';
import _debounce from 'lodash.debounce';
import { AnyObject } from '@/types';

type Props = {
  isHubspotFormLoaded: boolean;
  formRef: MutableRefObject<AnyObject>;
  setEmail: (data?: AnyObject) => void;
  setDebouncedEmail: (data?: AnyObject) => void;
};

export const useEmailFieldEventListener = ({
  isHubspotFormLoaded,
  formRef,
  setEmail,
  setDebouncedEmail,
}: Props) => {
  useEffect(() => {
    if (!isHubspotFormLoaded) {
      return;
    }
    const emailInput = formRef.current.querySelector('input[type=email]');
    if (!emailInput) {
      return;
    }
    const _setDebouncedEmail = _debounce(
      value => setDebouncedEmail(value),
      500,
    );
    const inputHandler = (e: any) => {
      const value = e.target.value;
      setEmail(value);
      _setDebouncedEmail(value);
    };
    emailInput.addEventListener('input', inputHandler);
    return () => {
      emailInput.removeEventListener('input', inputHandler);
    };
  }, [isHubspotFormLoaded, formRef]); // eslint-disable-line react-hooks/exhaustive-deps
};
