import React, { MutableRefObject } from 'react';
import { createPortal } from 'react-dom';

import { useTranslations } from 'next-intl';
import { QueryStatus } from '@/hooks/useQuery';
import { AnyObject } from '@/types';
import { getErrorMessage } from '../helpers';

export const EmailableStatus = ({
  formRef,
  status,
  data,
}: {
  formRef: MutableRefObject<AnyObject>;
  status: QueryStatus;
  data: AnyObject;
}) => {
  const t = useTranslations();
  const emailInput = formRef.current?.querySelector('input[type=email]');

  if (!emailInput) {
    return null;
  }
  const errorMessage = getErrorMessage(data);

  return createPortal(
    <>
      {status === QueryStatus.LOADING ? (
        <span className="spinner">
          <svg x="0px" y="0px" viewBox="0 0 50 50">
            <path
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
              transform="rotate(2.8182 25 25)"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              ></animateTransform>
            </path>
          </svg>
        </span>
      ) : null}
      {!emailInput.classList.contains('error') && errorMessage ? (
        <span className="emailable-error-label">{t(errorMessage)}</span>
      ) : null}
    </>,
    emailInput.parentElement,
  );
};
