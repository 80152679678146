import React from 'react';
import ContentLoader from 'react-content-loader';

type Props = {
  width?: number | string;
  height: number | string;
  radius?: number;
  style?: any;
};

const Rectangle = ({ width = '100%', height, radius = 8, style }: Props) => {
  return (
    <ContentLoader
      width={width}
      height={height}
      animate
      viewBox={
        typeof width === 'number' && typeof height === 'number'
          ? `0 0 ${width} ${height}`
          : undefined
      }
      foregroundOpacity={1}
      backgroundOpacity={1}
      backgroundColor="#F8F8FA"
      foregroundColor="#EEEFF1"
      style={{ ...style, display: 'block' }}
      speed={1}
    >
      <rect x={0} y={0} width="100%" height="100%" rx={radius} ry={radius} />
    </ContentLoader>
  );
};

export default Rectangle;
