import { AnyObject } from '@/types';

export const isValidEmail = (data: AnyObject) => {
  if (!data) {
    return false;
  }
  if (
    data.disposable ||
    data.reason === 'undeliverable' ||
    (data.score && data.score < 20) ||
    data.state === 'undeliverable' ||
    data.state === 'disposable'
  ) {
    return false;
  }
  return true;
};

export const getErrorMessage = (data: AnyObject) => {
  if (!data) {
    return undefined;
  }
  if (isValidEmail(data)) {
    return undefined;
  }
  return 'Please check the email and provide a valid business email';
};
