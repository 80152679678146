import _isNil from 'lodash.isnil';
import _reduce from 'lodash.reduce';

import { AnyObject } from '@/types';

export const serializeUrlParams = (
  urlParams: AnyObject,
  encode = true,
): string =>
  _reduce(
    urlParams,
    (acc, value, key) => {
      if (_isNil(value)) return acc;
      if (Array.isArray(value)) {
        if (value.length === 0) return acc;
        const encodedValue = _reduce(
          value,
          (accValue, val) =>
            accValue === ''
              ? `${key}=${encodeURIComponent(val)}`
              : `${accValue}&${key}=${encodeURIComponent(val)}`,
          '',
        );
        return acc === ''
          ? `?${encode ? encodedValue : value}`
          : `${acc}&${encode ? encodedValue : value}`;
      }
      return acc === ''
        ? `?${key}=${encode ? encodeURIComponent(value) : value}`
        : `${acc}&${key}=${encode ? encodeURIComponent(value) : value}`;
    },
    '',
  );
