import { useEffect } from 'react';

import { useTrackEvent } from '@/hooks/useTrackEvent';
import { AnyObject } from '@/types';

export const useEmailableTrackEvent = ({ data }: { data: AnyObject }) => {
  const trackEvent = useTrackEvent('emailable validation');

  useEffect(() => {
    if (data) {
      trackEvent(data);
    }
  }, [data, trackEvent]);
};
